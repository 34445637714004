import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../components/AllProdukNewArrival.css";
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Pagination } from "flowbite-react";
import apiClient from "../components/apiClient";
import Default from "../assets/default-image.png";

const formatRupiah = (angka) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka);
};

const hexToName = (hex) => {
  const colors = {
    "#ffffff": "White",
    "#0c0c0c": "Black Dove",
    "#E3A177": "Rose Gold",
    "#070707": "Matte Black",
    "#fefefe": "Light Chrome",
    "#f0f0f0": "Light Gray",
    "#808080": "Gray",
    "#000000": "Black",
    "#ff0000": "Red",
    "#00ff00": "Green",
    "#0000ff": "Blue",
    "#800080": "Purple",
    "#ffff00": "Yellow",
    "#ffa500": "Orange",
    "#800000": "Maroon",
    "#808000": "Olive",
    "#008000": "Dark Green",
    "#000080": "Navy Blue",
    "#ff00ff": "Magenta",
    "#c0c0c0": "Silver",
    "#a52a2a": "Brown",
    "#d3d3d3": "Light Gray",
    "#f5f5f5": "White Smoke",
    "#c0d6e4": "Light Steel Blue",
    "#ff6347": "Tomato",
    "#2e8b57": "Sea Green",
    "#3cb371": "Medium Sea Green",
    "#ff1493": "Deep Pink",
    "#00bfff": "Deep Sky Blue",
    "#00ced1": "Dark Turquoise",
    "#8a2be2": "Blue Violet",
    "#7fff00": "Chartreuse",
    "#adff2f": "Green Yellow",
    "#ffdead": "Navajo White",
    "#dda0dd": "Plum",
    "#ff4500": "Orange Red",
    "#2f4f4f": "Dark Slate Gray",
    "#bdb76b": "Dark Khaki",
    "#9acd32": "Yellow Green",
    "#8b4513": "Saddle Brown",
    "#ffb6c1": "Light Pink",
    "#cd5c5c": "Indian Red",
    "#b0e0e6": "Powder Blue",
    "#f0e68c": "Khaki",
    "#e0ffff": "Light Cyan",
  };

  return colors[hex.toLowerCase()] || hex;
};

const AllProdukNewArrival = () => {
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();

  const isNewProduct = (createdAt) => {
    const productDate = new Date(createdAt);
    const currentDate = new Date();
    return (
      productDate.getMonth() === currentDate.getMonth() &&
      productDate.getFullYear() === currentDate.getFullYear()
    );
  };

  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Select Category");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get("/kategori");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  // Fetch data dari API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Memeriksa data yang diterima
        setBrands(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const { kategoriId: urlKategoriId } = useParams();
  const kategoriId = urlKategoriId || localStorage.getItem("kategoriId");

  const [produk, setProduk] = useState([]);
  const [kategori, setKategori] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduk = async () => {
      try {
        setLoading(true);
        const produkResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/produk/all`
        );
        setProduk(produkResponse.data);
      } catch (err) {
        setError("Gagal mengambil data produk");
      } finally {
        setLoading(false);
      }
    };

    fetchProduk();
  }, []);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 7 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const handleImageClick = (id) => {
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  console.log(localStorage.getItem("userId"));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Number of items per page

  const totalPages = Math.ceil(produk.length / itemsPerPage);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = produk.slice(startIndex, endIndex);

  return (
    <>
      <div className="flex flex-col items-center text-center min-h-screen py-10">
        {loading && <p className="text-gray-500">Loading Product...</p>}
        {error && <p className="text-red-500">{error}</p>}

        <h1 className="text-4xl md:text-5xl font-extrabold mb-10">
          New Arrival
        </h1>
        {/* Dropdown Button */}
        {/* <div className="relative inline-block text-left">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-black text-white px-4 py-2 rounded-lg flex items-center justify-between w-48"
          >
            {selectedCategory}
            <svg
              className={`w-4 h-4 ml-2 transition-transform ${
                isOpen ? "rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>

          {isOpen && (
            <div className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
              <ul className="max-h-60">
                {categories.map((category) => (
                  <li
                    key={category.id}
                    className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      setSelectedCategory(category.nama_kategori);
                      console.log(`Selected: ${category.nama_kategori}`);
                      setIsOpen(false); // Close dropdown on selection
                    }}
                  >
                    {category.nama_kategori}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div> */}

        <h5 className="text-lg md:text-xl font-medium text-left w-full px-6 md:px-20 mb-6">
          Found {produk.length} {produk.length === 1 ? "Product" : "Products"}
        </h5>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full px-4 md:px-10">
          {currentItems.length > 0 ? (
            currentItems.slice(0, 16).map((item) => {
              const colors = Array.isArray(item.color)
                ? item.color
                : item.color
                ? [item.color]
                : [];
              return (
                <div
                  key={item.id}
                  className="bg-white shadow-md rounded-lg p-4 relative cursor-pointer hover:shadow-xl transition"
                  onClick={() => navigate(`/produk/all/${item.id}`)}
                >
                  {isNewProduct(item.createdAt) && (
                    <div className="absolute top-2 right-2 bg-gray-100 text-black px-3 py-1 text-sm font-medium">
                      NEW!
                    </div>
                  )}

                  {item.diskon && (
                    <div className="absolute top-2 left-2 bg-red-500 text-white px-3 py-1 text-sm font-bold">
                      {item.diskon}% OFF
                    </div>
                  )}

                  <img
                    className="w-full h-60 object-contain p-2 rounded-md"
                    src={
                      item.image && item.image.length > 0
                        ? item.image[0]
                        : Default
                    }
                    alt={item.nama_produk}
                  />

                  <div className="mt-4 flex flex-col md:items-center md:justify-between">
                    {/* Left Side: Product Name + Brand */}
                    <div className="flex flex-col">
                      <h3 className="text-base md:text-lg font-semibold text-gray-800 line-clamp-1">
                        {item.brand.nama_brand} {item.nama_produk}
                      </h3>
                      <span className="text-sm font-normal text-gray-900">
                        {item.kategori.nama_kategori}
                      </span>

                      {/* ✅ Colors in desktop only */}
                      <div className="hidden md:flex justify-center mt-2 space-x-2">
                        {colors.map((color, index) => (
                          <div
                            key={index}
                            className="w-5 h-5 rounded-full border border-gray-300 shadow"
                            style={{ backgroundColor: color }}
                          ></div>
                        ))}
                      </div>
                    </div>

                    {/* ✅ Colors in mobile only */}
                    <div className="flex md:hidden items-center justify-center space-x-2">
                      {colors.map((color, index) => (
                        <div
                          key={index}
                          className="w-5 h-5 rounded-full border border-gray-300 shadow"
                          style={{ backgroundColor: color }}
                        ></div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-2 flex flex-col justify-center">
                    {item.harga && item.harga.length > 0 ? (
                      item.diskon ? (
                        <>
                          <span className="text-red-500 font-bold text-sm md:text-lg">
                            {formatRupiah(
                              item.harga - (item.harga[0] * item.diskon) / 100
                            ).replace("Rp", "Rp ")}
                          </span>
                          <span className="text-gray-400 line-through text-sm">
                            {formatRupiah(item.harga[0]).replace("Rp", "Rp ")}
                          </span>
                        </>
                      ) : (
                        <span className="text-sm md:text-lg font-medium text-gray-700">
                          {formatRupiah(item.harga[0]).replace("Rp", "Rp ")}
                        </span>
                      )
                    ) : (
                      <span className="text-gray-500 text-sm">
                        Price not available
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex flex-col items-center">
              <img
                src="/product-not-found.avif"
                className="w-72 h-72"
                alt="Not found"
              />
              <p className="text-xl font-bold mt-4">
                Product By Category Not Found
              </p>
            </div>
          )}
        </div>

        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            showIcons
          />
        </div>
      </div>

      <div style={{ marginTop: "58px", marginBottom: "58px" }}>
        {/* Carousel for Brands */}
        <div
          style={{
            marginTop: "58px",
            marginBottom: "58px",
            backgroundColor: "white",
          }}
        >
          {brands.length > 0 && (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
            >
              {brands.map((brand, index) => (
                <img
                  key={index}
                  src={brand.image}
                  alt={`Brand ${index}`}
                  onClick={() => handleImageClick(brand.id)}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "170px",
                    objectFit: "contain",
                  }}
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllProdukNewArrival;
