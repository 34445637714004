import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirecting
import "../components/AllBrand.css";
import Footer from "../components/Footer";
import apiClient from "../components/apiClient";

function Ecatalog() {
  const [ecatalog, setEcatalog] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchEcatalog = async () => {
      try {
        setLoading(true);
        const ecatalogResponse = await apiClient.get("/ecatalog");
        setEcatalog(ecatalogResponse.data);
      } catch (err) {
        setError("Failed to fetch Ecatalog data");
      } finally {
        setLoading(false);
      }
    };

    fetchEcatalog();
  }, []);

  // const handleImageClick = (fileUrl) => {
  //   navigate("/viewer-pdf", { state: { fileUrl } });
  // };

  const handleImageClick = (fileUrl) => {
    window.open(fileUrl, "_blank"); // Opens PDF in a new tab
  };
  
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        {loading && <p>Loading Brands...</p>}
        {error && <p>{error}</p>}

        <h5
          style={{
            fontSize: "57px",
            fontWeight: "900",
            lineHeight: "68.98px",
          }}
        >
          Ecatalog
        </h5>

        {ecatalog.length > 0 && (
          // <div className="grid-container">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-8">
            {ecatalog.map((item) => (
              <div className="brand-card cursor-pointer" key={item.id}>
                <img
                  src={item.image}
                  alt={item.name}
                  className="brand-image w-full h-48 object-cover"
                  onClick={() => handleImageClick(item.file[0])} // Pass the PDF file URL
                />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer></Footer>
    </>
  );
}

export default Ecatalog;
