import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../components/DetailProdukKategori.css";
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import apiClient from "../components/apiClient";

const formatRupiah = (angka) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka);
};

const hexToName = (hex) => {
  const colors = {
    "#ffffff": "White",
    "#0c0c0c": "Black Dove",
    "#E3A177": "Rose Gold",
    "#070707": "Matte Black",
    "#fefefe": "Light Chrome",
    "#f0f0f0": "Light Gray",
    "#808080": "Gray",
    "#000000": "Black",
    "#ff0000": "Red",
    "#00ff00": "Green",
    "#0000ff": "Blue",
    "#800080": "Purple",
    "#ffff00": "Yellow",
    "#ffa500": "Orange",
    "#800000": "Maroon",
    "#808000": "Olive",
    "#008000": "Dark Green",
    "#000080": "Navy Blue",
    "#ff00ff": "Magenta",
    "#c0c0c0": "Silver",
    "#a52a2a": "Brown",
    "#d3d3d3": "Light Gray",
    "#f5f5f5": "White Smoke",
    "#c0d6e4": "Light Steel Blue",
    "#ff6347": "Tomato",
    "#2e8b57": "Sea Green",
    "#3cb371": "Medium Sea Green",
    "#ff1493": "Deep Pink",
    "#00bfff": "Deep Sky Blue",
    "#00ced1": "Dark Turquoise",
    "#8a2be2": "Blue Violet",
    "#7fff00": "Chartreuse",
    "#adff2f": "Green Yellow",
    "#ffdead": "Navajo White",
    "#dda0dd": "Plum",
    "#ff4500": "Orange Red",
    "#2f4f4f": "Dark Slate Gray",
    "#bdb76b": "Dark Khaki",
    "#9acd32": "Yellow Green",
    "#8b4513": "Saddle Brown",
    "#ffb6c1": "Light Pink",
    "#cd5c5c": "Indian Red",
    "#b0e0e6": "Powder Blue",
    "#f0e68c": "Khaki",
    "#e0ffff": "Light Cyan",
  };

  return colors[hex.toLowerCase()] || hex;
};

const DetailProdukKategori = () => {
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();

  const isNewProduct = (createdAt) => {
    const productDate = new Date(createdAt);
    const currentDate = new Date();
    return (
      productDate.getMonth() === currentDate.getMonth() &&
      productDate.getFullYear() === currentDate.getFullYear()
    );
  };

  // Fetch data dari API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Memeriksa data yang diterima
        setBrands(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const { kategoriId: urlKategoriId } = useParams();
  const kategoriId = urlKategoriId || localStorage.getItem("kategoriId");

  const [produk, setProduk] = useState([]);
  const [kategori, setKategori] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!kategoriId) {
      setError("Kategori tidak ditemukan");
      setLoading(false);
      return;
    }

    const fetchProduk = async () => {
      try {
        setLoading(true);
        const produkResponse = await apiClient.get(
          `/produk/kategori/${kategoriId}`
        );
        setProduk(produkResponse.data);
        const kategoriResponse = await apiClient.get(`/kategori/${kategoriId}`);
        setKategori(kategoriResponse.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    fetchProduk();
  }, [kategoriId]);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 7 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const handleImageClick = (id) => {
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  return (
    <>
      <div className="pt-24 flex flex-col items-center justify-center text-center min-h-screen mt-8">
        {loading && <p>Loading Kategori...</p>}
        {error && <p>{error}</p>}

        {kategori && (
          <h1 className="mb-10 text-xl md:text-4xl font-black">
            {kategori.nama_kategori}
          </h1>
        )}

        {/* Product Count */}
        <div className="mb-16 text-base md:text-2xl font-normal text-left w-full pl-8 md:pl-24">
          <h5>
            Found {produk.length} {produk.length === 1 ? "Product" : "Products"}
          </h5>
        </div>

        {/* Product Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full px-6">
          {produk.length > 0 ? (
            produk.map((item) => (
              <div
                key={item.id}
                onClick={() => navigate(`/produk/all/${item.id}`)}
                className="bg-white flex flex-col justify-between items-center text-center 
                     p-4 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 
                     cursor-pointer relative"
              >
                {/* NEW! Badge */}
                {isNewProduct(item.createdAt) && (
                  <div className="absolute top-2 right-2 bg-gray-100 text-black px-3 py-1 rounded-md font-medium text-sm shadow-md">
                    NEW!
                  </div>
                )}

                {/* Discount Badge */}
                {item.diskon && (
                  <div className="absolute top-2 left-2 bg-red-600 text-white px-3 py-1 rounded-md font-bold text-xs shadow-lg">
                    {item.diskon}% OFF
                  </div>
                )}

                {/* Product Image */}
                <img
                  src={
                    item.image && item.image.length > 0
                      ? item.image[0]
                      : "default-image.jpg"
                  }
                  alt={item.nama_produk}
                  className="w-full h-auto max-h-[250px] object-contain"
                />

                {/* Product Details */}
                <div className="flex flex-col items-center w-full mt-4 px-2">
                  {/* Product Name */}
                  <h3 className="text-lg font-bold">
                    {item.brand.nama_brand} {item.nama_produk}
                  </h3>

                  {/* Color Options */}
                  <div className="flex flex-wrap items-center gap-2 mt-2">
                    {item.color &&
                      Array.isArray(item.color) &&
                      item.color.map((color, index) => (
                        <div
                          key={index}
                          className="w-5 h-5 rounded-full border border-gray-500"
                          style={{ backgroundColor: color }}
                        ></div>
                      ))}
                  </div>

                  {/* Category & Price */}
                  <div className="flex justify-center items-center w-full mt-4">
                    {item.harga && (item.harga[0] || item.harga) ? (
                      item.diskon ? (
                        <div className="flex flex-col items-center">
                          <span className="text-red-500 font-bold text-sm md:text-lg">
                            {formatRupiah(
                              (item.harga[0] || item.harga) -
                                ((item.harga[0] || item.harga) * item.diskon) /
                                  100
                            )}
                          </span>
                          <span className="text-sm text-gray-400 line-through">
                            {formatRupiah(item.harga[0] || item.harga)}
                          </span>
                        </div>
                      ) : (
                        <p className="text-sm md:text-lg font-normal text-gray-900">
                          {formatRupiah(item.harga[0] || item.harga)}
                        </p>
                      )
                    ) : (
                      <span className="text-gray-500 text-sm">
                        Price not available
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center text-center col-span-full py-12">
              <img
                src="/product-not-found.avif"
                alt="Not found"
                className="w-72 h-72 object-contain"
              />
              <p className="text-2xl font-bold text-gray-800 mt-6">
                Product By Category Not Found
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-14 mb-14">
        {/* Carousel for Brands */}
        <div className="mt-14 mb-14 bg-white">
          {brands.length > 0 && (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
            >
              {brands.map((brand, index) => (
                <img
                  key={index}
                  src={brand.image}
                  alt={`Brand ${index}`}
                  onClick={() => handleImageClick(brand.id)}
                  className="cursor-pointer w-full h-[170px] object-contain"
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default DetailProdukKategori;
