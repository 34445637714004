import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "react-toastify/dist/ReactToastify.css"; // Import the Toastify styles
import { FaChevronDown, FaMinus } from "react-icons/fa"; // Menggunakan React Icons
import Fitur from "../components/Fitur";
import apiClient from "../components/apiClient";
import Footer from "../components/Footer";
import "@ckeditor/ckeditor5-ui/theme/globals/globals.css";
import "@ckeditor/ckeditor5-theme-lark/theme/theme.css";
import DOMPurify from "dompurify";

export const sanitizeHTML = (html) => ({
  __html: DOMPurify.sanitize(html),
});

const formatRupiah = (angka) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka);
};

let colorsData = {}; // To store the fetched colors data

// Function to fetch color data from the endpoint
const fetchColors = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/color`);
    const data = await response.json();
    colorsData = data.colorList.reduce((acc, color) => {
      acc[color.color_hex.toLowerCase()] = color.color_name;
      return acc;
    }, {});
    return colorsData;
  } catch (error) {
    console.error("Failed to fetch colors:", error);
    return {};
  }
};

const hexToName = (hex) => {
  return colorsData[hex.toLowerCase()] || hex;
};

// Fungsi untuk membuat rating bintang
const RatingStars = ({ rating }) => {
  const fullStar = "#CCAB5C";
  const emptyStar = "#9E9E9E";
  let stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <span key={i} style={{ color: fullStar, fontSize: "19px" }}>
          ★
        </span>
      );
    } else {
      stars.push(
        <span key={i} style={{ color: emptyStar, fontSize: "19px" }}>
          ★
        </span>
      );
    }
  }

  return <div>{stars}</div>;
};

const DetailProduk = () => {
  const [cartCount, setCartCount] = useState(
    parseInt(localStorage.getItem("cartCount")) || 0
  ); // Store the cart count state
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDropdownOpenSpesifictaion, setIsDropdownOpenSpesification] =
    useState(false);
  const [isDropdownOpenWhatsInTheBox, setIsDropdownOpenWhatsInTheBox] =
    useState(false);
  const [isDropdownOpenReview, setIsDropdownOpenReview] = useState(false);

  const { id } = useParams(); // Mengambil id dari URL
  const [produk, setProduk] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0); // Untuk melacak thumbnail yang dipilih
  const [rating, setRating] = useState(5); // Default rating 5
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);

  const [product, setProduct] = useState(null); // Define the state and setter here
  const [ratings, setRatings] = useState([]);
  const [ratingCount, setRatingCount] = useState(0);
  const [selectedPrice, setSelectedPrice] = useState(0);

  const handleCardClick = (id) => {
    // Redirect to product detail page
    navigate(`/produk/all/${id}`);

    // Reload the page to reflect the changes
    window.location.reload();
  };

  const handleImageClick = (id) => {
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  // Fetch data dari API
  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_BASE_URL}/brand`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data); // Memeriksa data yang diterima
  //       setBrands(data);
  //     })
  //     .catch((error) => console.error("Error fetching data:", error));
  // }, []);

  useEffect(() => {
    // Simulate fetching data
    const fetchBrands = async () => {
      // Replace with your actual data fetching logic
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/brand`
      );
      const data = await response.json();
      setBrands(data);
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    // localStorage.clear();
    const fetchProductDetails = async () => {
      try {
        const response = await apiClient.get(
          // `${process.env.REACT_APP_API_BASE_URL}/produk/all/${id}`
          "/produk/all/${id}"
        );
        if (response.status === 200) {
          console.log(response.data); // Check the structure of the response
          setProduct(response.data);
          setSelectedImage(response.data.imageUrls?.[0] || ""); // Set first image if available
          setSelectedPrice(response.data.harga?.[0] || 0); // Set first price if available
        }
      } catch (error) {
        console.error(
          "Error fetching product details:",
          error.response || error.message
        );
      }
    };
    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/produk/all`);
        const response = await apiClient.get("/produk/all");
        if (response.status === 200) {
          setProducts(response.data); // Asumsi bahwa API mengembalikan array produk
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1440 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1440, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2, // 👉 Show 2 items in mobile
    },
  };

  useEffect(() => {
    const syncCartCount = () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const newCartCount = cart.reduce((total, item) => total + item.jumlah, 0);
      setCartCount(newCartCount); // Perbarui state
    };

    const currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const apaankeq = currentCartItems.map((item) => {
      console.log("Item jumlah:", item.jumlah); // Logging item.jumlah for each item
    });

    // Menyinkronkan saat halaman di-mount
    syncCartCount();

    // Menambahkan event listener untuk perubahan localStorage
    window.addEventListener("storage", syncCartCount);

    return () => {
      window.removeEventListener("storage", syncCartCount);
    };
  }, []);

  useEffect(() => {
    // Mengambil data produk berdasarkan id
    apiClient
      // .get(`${process.env.REACT_APP_API_BASE_URL}/produk/all/${id}`)
      .get(`/produk/all/${id}`)
      .then((response) => {
        setProduk(response.data);
        setSelectedImage(response.data.imageUrls[0]); // Menetapkan gambar pertama sebagai gambar utama
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });

    // Mengambil jumlah rating produk berdasarkan id
    apiClient
      // .get(`${process.env.REACT_APP_API_BASE_URL}/rating/produk/${id}/rating-count`)
      .get(`/rating/produk/${id}/rating-count`)
      .then((response) => {
        if (response.data.ratingCount !== undefined) {
          setRatingCount(response.data.ratingCount); // Menyimpan jumlah rating
        }
      })
      .catch((error) => {
        console.error("Error fetching rating count:", error);
      });

    // Mengambil rata-rata rating produk berdasarkan id
    apiClient
      // .get(`${process.env.REACT_APP_API_BASE_URL}/rating/produk/${id}/ratings`)
      .get(`/rating/produk/${id}/ratings`)
      .then((response) => {
        if (response.data.length > 0) {
          const averageRating =
            response.data.reduce((acc, curr) => acc + curr.rating, 0) /
            response.data.length;
          setRating(Math.round(averageRating));
          setRatings(response.data); // Save the ratings data to state
        }
      })
      .catch((error) => {
        console.error("Error fetching ratings:", error);
      });
  }, [id]);

  useEffect(() => {
    const initializeColors = async () => {
      await fetchColors();
    };
    initializeColors();
  }, []);

  // Fungsi untuk menghitung harga setelah diskon
  const calculateDiscountedPrice = (price, discountPercentage) => {
    return price - price * (discountPercentage / 100);
  };

  // Fungsi untuk format persentase diskon dan menghitung penghematan
  const calculateSave = (price, discountedPrice) => {
    const saveAmount = price - discountedPrice;
    const savePercentage = (saveAmount / price) * 100;
    return { saveAmount, savePercentage };
  };

  // const handleAddToCart = () => {
  //   const isLoggedIn = localStorage.getItem("token");
  //   if (!isLoggedIn) {
  //     navigate("/login");
  //     return;
  //   }

  //   const hargaSetelahDiskon = produk.diskon
  //     ? produk.harga - (produk.harga * produk.diskon) / 100
  //     : produk.harga;

  //   const produkData = {
  //     produkId: produk.id,
  //     jumlah: 1,
  //     subtotal: hargaSetelahDiskon,
  //   };

  //   // let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   const currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   const existingProductIndex = currentCartItems.findIndex(
  //     (item) => item.produkId === produk.id
  //   );

  //   if (existingProductIndex >= 0) {
  //     currentCartItems[existingProductIndex].jumlah += 1;
  //     currentCartItems[existingProductIndex].subtotal =
  //       currentCartItems[existingProductIndex].jumlah * hargaSetelahDiskon;
  //   } else {
  //     currentCartItems.push(produkData);
  //   }

  //   // const filteredCartItems = currentCartItems.map(({ produkId, jumlah, subtotal }) => ({
  //   //   produkId,
  //   //   jumlah,
  //   //   subtotal,
  //   // }));

  //   const filteredCartItems = currentCartItems.map((item) => ({
  //     produkId: item.produkId,
  //     jumlah: item.jumlah,
  //     subtotal: item.subtotal,
  //   }));

  //   console.log("Current Cart Items:", filteredCartItems);

  //   axios
  //     .post(
  //       "http://192.168.1.3:2000/cart",
  //       //currentCartItems, // Send the array of cart items directly
  //       filteredCartItems,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log("Response Data:", response.data);
  //       const updatedCartItems =
  //         response.data.updatedCartItems || filteredCartItems;
  //       localStorage.setItem("cart", JSON.stringify(updatedCartItems));

  //       const updatedCartCount = updatedCartItems.reduce(
  //         (total, item) => total + item.jumlah,
  //         0
  //       );
  //       setCartCount(updatedCartCount);
  //       window.location.reload();

  //       toast.success("Produk berhasil ditambahkan ke keranjang!", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error adding product to cart:", error);
  //       toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     });
  // };

  // const handleAddToCart = () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/login");
  //     return;
  //   }

  //   const hargaSetelahDiskon = produk.diskon
  //     ? produk.harga[index] - (produk.harga[index] * produk.diskon) / 100
  //     : produk.harga[index];

  //   if (selectedIndex === null) {
  //     toast.error("Please select a color.", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });
  //     return;
  //   }

  //   const selectedColorHex = produk.color[selectedIndex];
  //   const selectedColorName = hexToName(selectedColorHex);

  //   let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   console.log("Initial cart:", JSON.stringify(currentCartItems, null, 2));

  //   const existingProductIndex = currentCartItems.findIndex(
  //     (item) =>
  //       item.produkId === produk.id && item.color_hex === selectedColorHex
  //   );

  //   let newCartItem;

  //   if (existingProductIndex >= 0) {
  //     // FIX: Do not modify jumlah here, let the backend handle it
  //     newCartItem = {
  //       ...currentCartItems[existingProductIndex],
  //       jumlah: currentCartItems[existingProductIndex].jumlah + 1,
  //     };
  //     currentCartItems[existingProductIndex] = newCartItem;
  //   } else {
  //     newCartItem = {
  //       produkId: produk.id,
  //       jumlah: 1, // Start with 1
  //       subtotal: hargaSetelahDiskon,
  //       colorName: selectedColorName,
  //       color_hex: selectedColorHex,
  //     };
  //     currentCartItems.push(newCartItem);
  //   }

  //   localStorage.setItem("cart", JSON.stringify(currentCartItems));

  //   console.log(
  //     "Final cart before sending:",
  //     JSON.stringify(currentCartItems, null, 2)
  //   );

  //   apiClient
  //     .post("/cart", [newCartItem], {
  //       // Send only the updated item
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Response Data:", response.data);

  //       const updatedCartItems =
  //         response.data.updatedCartItems || currentCartItems;

  //       localStorage.setItem("cart", JSON.stringify(updatedCartItems));

  //       const updatedCartCount = updatedCartItems.reduce(
  //         (total, item) => total + parseInt(item.jumlah, 10),
  //         0
  //       );

  //       setCartCount(updatedCartCount);
  //       window.location.reload();

  //       toast.success("Produk berhasil ditambahkan ke keranjang!", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error adding product to cart:", error);
  //       toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     });
  // };

  // const handleAddToCart = () => {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     navigate("/login");
  //     return;
  //   }

  //   if (selectedIndex === null) {
  //     toast.error("Please select a color.", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //     });
  //     return;
  //   }

  //   // Use selectedIndex instead of index
  //   const hargaSetelahDiskon = produk.diskon
  //     ? produk.harga[selectedIndex] - (produk.harga[selectedIndex] * produk.diskon) / 100
  //     : produk.harga[selectedIndex];

  //   const selectedColorHex = produk.color[selectedIndex];
  //   const selectedColorName = hexToName(selectedColorHex);

  //   let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

  //   console.log("Initial cart:", JSON.stringify(currentCartItems, null, 2));

  //   const existingProductIndex = currentCartItems.findIndex(
  //     (item) =>
  //       item.produkId === produk.id && item.color_hex === selectedColorHex
  //   );

  //   let newCartItem;

  //   if (existingProductIndex >= 0) {
  //     newCartItem = {
  //       ...currentCartItems[existingProductIndex],
  //       jumlah: currentCartItems[existingProductIndex].jumlah + 1,
  //     };
  //     currentCartItems[existingProductIndex] = newCartItem;
  //   } else {
  //     newCartItem = {
  //       produkId: produk.id,
  //       jumlah: 1,
  //       subtotal: hargaSetelahDiskon,
  //       colorName: selectedColorName,
  //       color_hex: selectedColorHex,
  //     };
  //     currentCartItems.push(newCartItem);
  //   }

  //   localStorage.setItem("cart", JSON.stringify(currentCartItems));

  //   console.log("Final cart before sending:", JSON.stringify(currentCartItems, null, 2));

  //   apiClient
  //     .post("/cart", [newCartItem], {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("Response Data:", response.data);

  //       const updatedCartItems = response.data.updatedCartItems || currentCartItems;

  //       localStorage.setItem("cart", JSON.stringify(updatedCartItems));

  //       const updatedCartCount = updatedCartItems.reduce(
  //         (total, item) => total + parseInt(item.jumlah, 10),
  //         0
  //       );

  //       setCartCount(updatedCartCount);
  //       window.location.reload();

  //       toast.success("Produk berhasil ditambahkan ke keranjang!", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error adding product to cart:", error);
  //       toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
  //         position: "bottom-right",
  //         autoClose: 3000,
  //       });
  //     });
  // };

  const handleAddToCart = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (selectedIndex === null || selectedIndex === undefined) {
      toast.error("Please select a color.", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return;
    }

    if (!produk.harga || !produk.harga[selectedIndex]) {
      toast.error("Invalid price data. Please try again.", {
        position: "bottom-right",
        autoClose: 3000,
      });
      return;
    }

    // Ensure prices are numbers
    // const selectedPrice = parseFloat(produk.harga[selectedIndex]);
    const selectedPrice =
      Array.isArray(produk.harga) && produk.harga[selectedIndex] !== undefined
        ? parseFloat(produk.harga[selectedIndex])
        : 0;

    // Store selected price in localStorage
    localStorage.setItem("selectedPrice", JSON.stringify(selectedPrice));

    const hargaSetelahDiskon = produk.diskon
      ? selectedPrice - (selectedPrice * produk.diskon) / 100
      : selectedPrice;

    const selectedColorHex = produk.color[selectedIndex];
    const selectedColorName = hexToName(selectedColorHex);

    let currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    console.log("Initial cart:", JSON.stringify(currentCartItems, null, 2));

    const existingProductIndex = currentCartItems.findIndex(
      (item) =>
        item.produkId === produk.id && item.color_hex === selectedColorHex
    );

    let newCartItem;

    if (existingProductIndex >= 0) {
      const existingItem = currentCartItems[existingProductIndex];

      newCartItem = {
        ...existingItem,
        jumlah: parseInt(existingItem.jumlah, 10) + 1, // Convert jumlah to number before addition
        subtotal: parseFloat(existingItem.subtotal) + hargaSetelahDiskon, // Convert subtotal to number before addition
      };

      currentCartItems[existingProductIndex] = newCartItem;
    } else {
      newCartItem = {
        produkId: produk.id,
        jumlah: 1,
        subtotal: hargaSetelahDiskon,
        colorName: selectedColorName,
        color_hex: selectedColorHex,
      };
      currentCartItems.push(newCartItem);
    }

    // ✅ Log values to ensure correct data types
    console.log("Subtotal for selected item (number):", newCartItem.subtotal);
    console.log(
      "Updated Cart Items:",
      JSON.stringify(currentCartItems, null, 2)
    );

    localStorage.setItem("cart", JSON.stringify(currentCartItems));

    apiClient
      .post("/cart", [newCartItem], {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Response Data:", response.data);

        const updatedCartItems =
          response.data.updatedCartItems || currentCartItems;

        localStorage.setItem("cart", JSON.stringify(updatedCartItems));

        const updatedCartCount = updatedCartItems.reduce(
          (total, item) => total + parseInt(item.jumlah, 10),
          0
        );

        setCartCount(updatedCartCount);
        window.location.reload();

        toast.success("Produk berhasil ditambahkan ke keranjang!", {
          position: "bottom-right",
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
        toast.error("Terjadi kesalahan saat menambahkan produk ke keranjang.", {
          position: "bottom-right",
          autoClose: 3000,
        });
      });
  };

  const handlePreorderAllClick = () => {
    navigate("/login");
  };

  const updateCartCount = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const cartCount = cart.reduce((total, item) => total + item.jumlah, 0);
    localStorage.setItem("cartCount", cartCount); // Save the cart count
  };
  const handleThumbnailClick = (image, index) => {
    setSelectedImage(image);
    setSelectedIndex(index);
  };

  // const handleColorClick = (index) => {
  //   if (produk.imageUrls && produk.imageUrls[index]) {
  //     setSelectedImage(produk.imageUrls[index]);
  //     setSelectedIndex(index);
  //   }
  //   const selectedColor = produk.color[index];
  //   const selectedPrice = produk.harga[index];
  //   console.log(`Selected color at index ${index}: ${selectedColor}`);
  //   const colorName = hexToName(selectedColor,selectedPrice);
  //   console.log(
  //     `Selected color at index ${index}: ${selectedColor} (${colorName})`
  //   );
  //   console.log(produk.harga[index]);

  // };

  const handleColorClick = (index) => {
    if (!produk || !produk.color || !produk.harga) return;

    setSelectedIndex(index);
    setSelectedImage(produk.imageUrls?.[index] || ""); // Ensure there's an image
    setSelectedPrice(produk.harga?.[index] || 0); // Ensure there's a price

    // Store selectedPrice in localStorage
    localStorage.setItem(
      "selectedPrice",
      JSON.stringify(produk.harga?.[index] || 0)
    );

    console.log(`Selected color: ${produk.color[index]}`);
    console.log(`Selected price: ${produk.harga[index]}`);
  };

  if (!produk) {
    return <p>Loading...</p>;
  }

  const discountedPrice = produk.diskon
    ? calculateDiscountedPrice(produk.harga, produk.diskon)
    : null;
  const { saveAmount, savePercentage } = discountedPrice
    ? calculateSave(produk.harga, discountedPrice)
    : { saveAmount: 0, savePercentage: 0 };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const toggleDropdowns = () => {
    setIsDropdownOpenSpesification((prev) => !prev);
  };
  const toggleDropdownss = () => {
    setIsDropdownOpenWhatsInTheBox((prev) => !prev);
  };
  const toggleDropdownsss = () => {
    setIsDropdownOpenReview((prev) => !prev);
  };

  fetchColors();
  const currentCartItems = JSON.parse(localStorage.getItem("cart")) || [];
  const apaankeq = currentCartItems.map((item) => {
    console.log("Item jumlah:", item.jumlah); // Logging item.jumlah for each item
  });

  // function stripHTML(html) {
  //   const stripped = html.replace(/<[^>]+>/g, "");          // Hapus tag HTML
  //   const decoded = stripped
  //     .replace(/&nbsp;/gi, " ")                             // Hapus &nbsp;
  //     .replace(/&amp;/gi, "&")                              // Hapus &amp;
  //     .replace(/&lt;/gi, "<")
  //     .replace(/&gt;/gi, ">")
  //     .replace(/&quot;/gi, "\"")
  //     .replace(/&#39;/gi, "'");

  //   return decoded.trim();                                  // Hapus spasi di awal/akhir
  // }

  function stripHTML(input) {
    if (!input) return "";

    // Ganti <br> dan <br/> jadi \n biar kita bisa proses di React
    let text = input
      .replace(/<br\s*\/?>/gi, "\n" || /<br\s*\/?<br\s*\/?>/gi, "\n") // Ganti <br> atau <br /> jadi newline
      .replace(/&nbsp;/gi, " ") // Ganti &nbsp; dengan spasi
      .replace(/<[^>]*>?/gm, "") // Hapus semua tag HTML
      .trim();

    return text;
  }

  return (
    <>
      <div className="p-3" style={{ marginBottom: "100px" }}>
        <ToastContainer />

        <div className="bg-gray-100 mt-[100px] sm:px-8 md:px-16 lg:px-40">
          <div className="flex flex-col lg:flex-row items-center px-4 p-8">
            {/* Image + Thumbnails Section */}
            <div className="flex flex-col-reverse lg:flex-row lg:w-3/5 w-full mb-8 lg:mb-0">
              {/* ✅ Brand Image (mobile: show above selected image, desktop: stay in details section) */}
              {/* <div className="block lg:hidden mb-4">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-48 h-24 object-contain"
                    src={produk.Brand.imageUrl}
                    alt={produk.Brand.nama_brand}
                  />
                </div>
              </div> */}
              {/* Thumbnails - horizontal in mobile, vertical in desktop */}
              <div className="flex flex-row lg:flex-col overflow-x-auto lg:overflow-y-auto space-x-4 lg:space-x-0 lg:space-y-4 mt-4 lg:mt-0 lg:mr-4">
                {produk.imageUrls &&
                  produk.imageUrls.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => handleThumbnailClick(img, index)}
                      className={`w-16 h-16 object-cover rounded-lg cursor-pointer ${
                        selectedIndex === index ? "border-2 border-black" : ""
                      }`}
                    />
                  ))}
              </div>

              {/* Selected Image */}
              {/* <div className="flex-1 bg-gray-100 flex items-center justify-center p-4 sm:p-6 md:p-8 lg:p-10 rounded-lg"> */}
              <div className="flex-1 bg-gray-100 flex items-center justify-center p-6 sm:p-8 md:p-10 lg:p-12 rounded-2xl min-h-[315px] min-w-[320px] shadow-lg ">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt={produk.nama_produk}
                    className="max-w-full max-h-[500px] object-contain rounded-lg transition-transform duration-300 hover:scale-105"
                  />
                ) : (
                  <p className="text-gray-500 text-lg font-semibold">
                    No image selected
                  </p>
                )}
              </div>
            </div>

            {/* Product Details Section */}
            <div className="flex-1 w-full px-4 lg:px-8 order-3 lg:order-none">
              <div className="flex flex-col space-y-6">
                {/* <div className="hidden lg:flex items-center space-x-4">
                  <img
                    className="w-48 h-24 object-contain"
                    src={produk.Brand.imageUrl}
                    alt={produk.Brand.nama_brand}
                  />
                </div> */}
                <div className="flex flex-col space-y-2">
                  <h3 className="text-2xl font-semibold">
                    {produk.Brand.nama_brand} {produk.nama_produk}
                  </h3>

                  <h6 className="text-xl font-light">
                    Category:{" "}
                    <span className="text-gray-600">
                      {produk.Kategori.nama_kategori}
                    </span>
                  </h6>

                  {/* Rating section */}
                  <div className="flex items-center justify-between">
                    <RatingStars rating={rating} />
                    <p className="text-xl font-medium">{rating}/5</p>
                  </div>
                </div>

                {/* <div className="flex flex-wrap space-x-4 mb-4 gap-12">
                  {produk.color?.map((color, index) => (
                    <div
                      key={index}
                      onClick={() => handleColorClick(index)}
                      className={`flex flex-col items-center mb-4 cursor-pointer ${
                        selectedIndex === index ? "border-2 border-black" : ""
                      }`}
                    >
                      <div
                        className="w-8 h-8 rounded-full mb-2"
                        style={{ backgroundColor: color }}
                      ></div>
                      <p className="text-sm text-center">{hexToName(color)}</p>
                    </div>
                  ))}
                </div> */}

                <div className="flex flex-col md:flex-row justify-between gap-6 mb-4">
                  {/* Left Column - Colors */}
                  {/* <div className="flex flex-wrap gap-4 md:w-1/2"> */}
                  {/* <div className="flex flex-row gap-4 flex-wrap">
                    {produk.color?.map((color, index) => (
                      <div
                        key={index}
                        onClick={() => handleColorClick(index)}
                        className={`flex flex-col items-center cursor-pointer ${
                          selectedIndex === index
                            ? "border-2 border-black rounded-md p-1"
                            : ""
                        }`}
                      >
                        <div
                          className="w-6 h-6 rounded-full mb-1"
                          style={{ backgroundColor: color }}
                        ></div>
                        <p className="text-sm text-center">
                          {hexToName(color)}
                        </p>
                      </div>
                    ))}
                  </div> */}

                  <div className="flex flex-col items-start gap-2">
                    <div className="flex flex-row gap-4 flex-wrap">
                      {produk.color?.map((color, index) => (
                        <div
                          key={index}
                          onClick={() => handleColorClick(index)}
                          className={`p-0.5 rounded-full cursor-pointer border ${
                            selectedIndex === index
                              ? "border-2 border-black"
                              : "border-gray-300"
                          }`}
                        >
                          <div
                            className="w-5 h-5 rounded-full"
                            style={{ backgroundColor: color }}
                          ></div>
                        </div>
                      ))}
                    </div>
                    <p className="text-sm text-gray-700">
                      {hexToName(produk.color[selectedIndex])}
                    </p>
                  </div>

                  {/* Right Column - Price */}
                  <div className="flex flex-col justify-start md:items-end md:w-1/2">
                    {discountedPrice ? (
                      <>
                        <p className="text-2xl font-semibold text-gray-800">
                          {formatRupiah(discountedPrice)}
                        </p>
                        <p className="text-sm text-gray-400 line-through">
                          {formatRupiah(selectedPrice || produk.harga[0])}
                        </p>
                        {savePercentage && (
                          <p className="text-red-500 text-sm font-medium">
                            Save {savePercentage.toFixed(0)}%
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="text-medium font-semibold text-gray-800">
                        {formatRupiah(selectedPrice || produk.harga[0])}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col items-start space-y-4">
                  {/* {discountedPrice ? (
                    <>
                      <p className="text-2xl font-semibold text-gray-800">
                        {formatRupiah(discountedPrice)}
                      </p>
                      <p className="text-sm text-gray-400 line-through">
                        {formatRupiah(selectedPrice || produk.harga[0])}
                      </p>
                      {savePercentage && (
                        <p className="text-red-500 text-sm font-medium">
                          Save {savePercentage.toFixed(0)}%
                        </p>
                      )}
                    </>
                  ) : (
                    <p className="text-2xl font-semibold text-gray-800">
                      {formatRupiah(selectedPrice || produk.harga[0])}
                    </p>
                  )} */}

                  <button
                    onClick={handleAddToCart}
                    className="w-full py-3 text-lg font-semibold text-white bg-black rounded-md mt-4 mb-4"
                  >
                    Add to Cart
                  </button>
                  <div className="text-[16px] leading-snug text-left">
                    <p className="text-black font-medium">
                      Free normal delivery:
                    </p>
                    <p className="text-gray-500">3–5 working days.</p>
                  </div>
                  <div className="text-[16px] leading-snug text-left">
                    <p className="text-black font-medium">Store pickup:</p>
                    <p className="text-gray-500">
                      Usually ready in 30 minutes.
                    </p>
                  </div>
                  <div className="text-[16px] leading-snug text-left">
                    <p className="text-black font-medium">Same Day Delivery:</p>
                    <p className="text-gray-500">Order by 5pm.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-32 px-4 sm:px-8 md:px-16 lg:px-40">
          {/* Description Header */}
          <div
            onClick={toggleDropdown}
            className="text-lg sm:text-xl font-medium cursor-pointer flex items-center justify-between border-b border-gray-300 pb-3"
          >
            <span>Description</span>
            {isDropdownOpen ? (
              <FaMinus className="text-black text-2xl" />
            ) : (
              <FaChevronDown className="text-black text-2xl" />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpen && (
            <div
              className="ck-content mt-4 whitespace-pre-wrap text-gray-700"
              dangerouslySetInnerHTML={sanitizeHTML(
                produk.deskripsi || "No description available."
              )}
            />
          )}
        </div>

        <div className="mt-12 px-4 sm:px-8 md:px-16 lg:px-40">
          {/* Spesification Header */}
          <div
            onClick={toggleDropdowns}
            className="text-lg sm:text-xl font-medium cursor-pointer flex items-center justify-between border-b border-gray-300 pb-3"
          >
            <span>Spesification</span>
            {isDropdownOpenSpesifictaion ? (
              <FaMinus className="text-black text-2xl" />
            ) : (
              <FaChevronDown className="text-black text-2xl" />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpenSpesifictaion && (
            <div
              className="mt-4 text-base text-gray-700 leading-relaxed"
              dangerouslySetInnerHTML={sanitizeHTML(
                produk.spesifikasi || "No spesification available."
              )}
            />
          )}
        </div>

        <div className="mt-12 px-4 sm:px-8 md:px-16 lg:px-40">
          {/* Header */}
          <div
            onClick={toggleDropdownss}
            className="text-lg sm:text-xl font-medium cursor-pointer flex items-center justify-between border-b border-gray-300 pb-3"
          >
            <span>What’s in the box</span>
            {isDropdownOpenWhatsInTheBox ? (
              <FaMinus className="text-black text-2xl" />
            ) : (
              <FaChevronDown className="text-black text-2xl" />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpenWhatsInTheBox && (
            <div
              className="mt-4 text-base text-gray-700 leading-relaxed"
              dangerouslySetInnerHTML={sanitizeHTML(
                produk.keterangan || "No data available."
              )}
            />
          )}
        </div>

        <div className="mt-12 px-4 sm:px-8 md:px-16 lg:px-40">
          {/* Header */}
          <div
            onClick={toggleDropdownsss}
            className="text-lg sm:text-xl font-medium cursor-pointer flex items-center justify-between border-b border-gray-300 pb-3"
          >
            <span>Review</span>
            {isDropdownOpenReview ? (
              <FaMinus className="text-black text-2xl" />
            ) : (
              <FaChevronDown className="text-black text-2xl" />
            )}
          </div>

          {/* Dropdown Content */}
          {isDropdownOpenReview && (
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8 text-gray-700 text-base leading-relaxed">
              {/* Rating Summary */}
              <div className="flex flex-col items-start space-y-4">
                <div className="flex items-center space-x-4">
                  <div className="flex text-yellow-500 text-5xl">★</div>
                  <div className="flex items-baseline space-x-2">
                    <p className="text-6xl font-light text-black">{rating}</p>
                    <span className="text-xl">/5.0</span>
                  </div>
                </div>
                <p className="text-lg text-gray-500">
                  {ratingCount > 0 ? `${ratingCount} Ratings` : "0 Ratings"}
                </p>
              </div>

              {/* Individual Reviews */}
              <div>
                <h3 className="text-3xl font-semibold mb-6">Review</h3>
                {ratings && ratings.length > 0 ? (
                  <div className="flex flex-col space-y-10">
                    {ratings.map((ratingItem, index) => (
                      <div key={ratingItem.id} className="space-y-6">
                        {/* Stars */}
                        <div className="flex text-4xl text-yellow-500">
                          {Array.from({ length: 5 }, (_, i) => (
                            <span
                              key={i}
                              className={
                                i < ratingItem.rating
                                  ? "text-[#CCAB5C]"
                                  : "text-gray-300"
                              }
                            >
                              ★
                            </span>
                          ))}
                        </div>

                        {/* User Info */}
                        <div className="flex items-center space-x-4">
                          <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
                            <i className="fas fa-user text-white text-xl" />
                          </div>
                          <p className="font-semibold text-lg text-gray-800">
                            {ratingItem.User?.nama
                              ? `${ratingItem.User.nama.charAt(
                                  0
                                )}*${ratingItem.User.nama.slice(-1)}`
                              : "Anonymous"}
                          </p>
                        </div>

                        {/* Review Text */}
                        {ratingItem.review && (
                          <p className="text-gray-600 text-base">
                            {ratingItem.review}
                          </p>
                        )}

                        {/* Media */}
                        <div className="flex flex-wrap gap-4">
                          {ratingItem.imageUrl && (
                            <img
                              src={ratingItem.imageUrl}
                              alt="User Upload"
                              className="w-full sm:w-1/2 rounded-lg object-cover"
                            />
                          )}
                          {ratingItem.videoUrl && (
                            <video
                              controls
                              className="w-full sm:w-1/2 rounded-lg"
                            >
                              <source
                                src={ratingItem.videoUrl}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </div>

                        {index < ratings.length - 1 && (
                          <hr className="mt-6 border-t border-gray-300" />
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">No reviews available.</p>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="w-full py-24 px-4 text-4xl font-medium">
          <div>
            <div className="text-center">You may also like</div>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              className="mt-5"
            >
              {products.map((product) => (
                <div
                  key={product.id}
                  className="relative p-2 cursor-pointer shadow-lg mx-2 h-[320px] flex flex-col"
                  onClick={() => handleCardClick(product.id)} // Replace with handleImageClick
                >
                  {product.diskon && (
                    <div className="absolute top-5 left-5 bg-red-500 text-white px-3 py-1 rounded font-semibold text-sm gap-6">
                      {product.diskon}% OFF
                    </div>
                  )}
                  <div className="flex-grow flex items-center justify-center">
                    <div className="w-48 h-28 flex items-center justify-center">
                      <img
                        src={product.image[0]} // Access the first image
                        alt={product.nama_produk}
                        className="max-w-full max-h-full object-contain"
                      />
                    </div>
                  </div>
                  <h4 className="flex justify-start text-sm sm:text-base md:text-base lg:text-base font-semibold mt-2 mb-4">
                    {product.nama_produk}
                  </h4>
                  <div className="flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center p-5 gap-4 sm:gap-0">
                    {/* Color Options - will appear below price on mobile */}
                    <div className="flex justify-start">
                      {product.color &&
                        product.color.map((color, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center mr-2"
                          >
                            <div
                              className="w-4 h-4 rounded-full mb-2"
                              style={{ backgroundColor: color }}
                            ></div>
                          </div>
                        ))}
                    </div>

                    {/* Harga - will appear above colors on mobile */}
                    <div className="flex flex-col items-start sm:items-end">
                      <p className="text-sm sm:text-base md:text-base font-semibold text-gray-900">
                        {formatRupiah(
                          product.harga[0] - (product.diskon || 0)
                        ).replace("Rp", "Rp ")}
                      </p>
                      {product.diskon && (
                        <p className="text-sm text-black line-through">
                          {formatRupiah(
                            product.harga[0] || product.harga
                          ).replace("Rp", "Rp ")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <Fitur />

        <div className="mt-7 mb-14 bg-white px-4 sm:px-6 md:px-10 lg:px-8">
          {brands.length > 0 && (
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              itemClass="px-2" // adds spacing between items
            >
              {brands.map((brand, index) => (
                <img
                  key={index}
                  src={brand.image}
                  alt={`Brand ${index}`}
                  onClick={() => handleImageClick(brand.id)}
                  className="scale-150 cursor-pointer w-full h-[120px] sm:h-[140px] md:h-[160px] lg:h-[170px] object-contain transition-transform duration-300 hover:scale-180"
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default DetailProduk;
