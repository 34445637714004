import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirecting
import "../components/AllBrand.css";
import Footer from "../components/Footer";
import apiClient from "../components/apiClient";

function AllBrand() {
  const [produk, setProduk] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchProduk = async () => {
      try {
        setLoading(true);
        const produkResponse = await apiClient.get("/brand");
        setProduk(produkResponse.data);
      } catch (err) {
        setError("Gagal mengambil data Brands");
      } finally {
        setLoading(false);
      }
    };

    fetchProduk();
  }, []);

  // const sortedProduk = produk.sort((a, b) =>
  //   a.nama_brand.localeCompare(b.nama_brand, undefined, { sensitivity: "base" })
  // );

  const handleImageClick = (id) => {
    // Set brandId in localStorage
    localStorage.setItem("brandId", id);
    // Navigate to the detail page
    navigate(`/detail-brand/${id}`);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center text-center min-h-screen md:p-8">
        {loading && <p>Loading Brands...</p>}
        {error && <p>{error}</p>}

        <h5 className="text-5xl font-extrabold leading-tight">BRANDS</h5>

        {produk.length > 0 && (
          // <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-8">
             <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-8">
            {/* <div className="brand-grid"> */}
            {produk.map((item) => (
              <div
                key={item.id}
                className="brand-card cursor-pointer hover:shadow-xl transition"
                onClick={() => handleImageClick(item.id)}
              >
                <img
                  src={item.image}
                  alt={item.nama_brand}
                  className="brand-image w-full h-48 object-cover md:scale-150"
                />
                {/* <p className="mt-2 text-lg font-medium">{item.nama_brand}</p> */}
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default AllBrand;
