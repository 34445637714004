import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Kategori.css"; // Tambahkan file CSS untuk styling
import apiClient from "../components/apiClient";

function Kategori() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get("/kategori");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    localStorage.setItem("kategoriId", categoryId);
    navigate(`/produk-kategori/${categoryId}`);
  };

  return (
    <div className="kategori-container">
      {/* First Row (0-2) */}
      {/* <div className="kategori-row">
        {categories.slice(0, 2).map((category) => (
          <div
            key={category.id}
            className="kategori-item"
            onClick={() => handleCategoryClick(category.id)}
          >
            <img
              src={category.image}
              alt={category.nama_kategori}
              className="kategori-image"
            />
            <h6 className="kategori-name">{category.nama_kategori}</h6>
            <span className="kate-name">{category.nama_kategori}</span>
          </div>
        ))}
      </div> */}

      <div className="kategori-row">
        {categories
          .slice(0, window.innerWidth < 768 ? 1 : 2)
          .map((category) => (
            <div
              key={category.id}
              className="kategori-item"
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={category.image}
                alt={category.nama_kategori}
                className="kategori-image"
              />
              <h6 className="kategori-name">{category.nama_kategori}</h6>
              <span className="kate-name">{category.nama_kategori}</span>
            </div>
          ))}
      </div>

      {/* Second Row (2-5) */}
      <div className="kategori-row">
        {categories
          .slice(window.innerWidth < 768 ? 1 : 2, window.innerWidth < 768 ? 5 : 5)
          .map((category, index) => (
            <div
              key={category.id}
              className={`kategori-item ${
                index === 1 ? "kategori-center" : ""
              }`} // Center column styling
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={category.image}
                alt={category.nama_kategori}
                className="kategori-image"
              />
              <h6 className="kategori-name">{category.nama_kategori}</h6>
              <span className="kate-name">{category.nama_kategori}</span>
            </div>
          ))}
      </div>

      {/* Third Row (5-8) */}
      <div className="kategori-row">
        {categories
          .slice(window.innerWidth < 768 ? 5 : 5, window.innerWidth < 768 ? 6 : 8)
          .map((category, index) => (
            <div
              key={category.id}
              className={`kategori-item ${index === 2 ? "kategori-last" : ""}`} // Last column styling
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={category.image}
                alt={category.nama_kategori}
                className="kategori-image"
              />
              <h6 className="kategori-name">{category.nama_kategori}</h6>
              <span className="kate-name">{category.nama_kategori}</span>
            </div>
          ))}
      </div>

      <div className="kategori-row md:hidden">
        {categories
          .slice(6, 10)
          .map((category, index) => (
            <div
              key={category.id}
              className={`kategori-item ${
                index === 1 ? "kategori-center" : ""
              }`} // Center column styling
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={category.image}
                alt={category.nama_kategori}
                className="kategori-image"
              />
              <h6 className="kategori-name">{category.nama_kategori}</h6>
              <span className="kate-name">{category.nama_kategori}</span>
            </div>
          ))}
      </div>

      {/* Fourth Row (8-10) - Now Two Items Like First Row */}
      <div className="kategori-container hidden md:block" style={{ padding: "0px" }}>
        <div className="kategori-row">
          {categories.slice(8, 10).map((category) => (
            <div
              key={category.id}
              className="kategori-item"
              onClick={() => handleCategoryClick(category.id)}
            >
              <img
                src={category.image}
                alt={category.nama_kategori}
                className="kategori-image"
              />
              <h6 className="kategori-name">{category.nama_kategori}</h6>
              <span className="kate-name">{category.nama_kategori}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Kategori;
